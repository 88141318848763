import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  MenuAlt1Icon,
  MenuAlt2Icon,
  MenuAlt3Icon,
  MenuAlt4Icon,
  MenuIcon,
} from "@heroicons/react/outline";
import { AnimatePresence } from "framer-motion";
import { GlobalLogo } from "../lib/SvgLogo";
import Link from "next/link";
import Image from "next/legacy/image";
import { twMerge } from "tailwind-merge";
import * as Text from "@/components/text";
import BlockingOverlay from "../lib/BlockingOverlay";
import { CheckCircleIcon } from "@heroicons/react/solid";
import InitiativesPopover from "./InitiativesPopover";
import classNames from "classnames";

const initiatives = {
  "gira-ambulante": {
    accent: "text-gira group-hover:text-neutral-900",
    "positive-text": "text-neutral-300",
    "negative-text": "hover:text-neutral-900 hover:bg-gira",
    "link-positive": "dark",
    "link-negative": "light",
    hr: "border-gira group-hover:border-neutral-900",
    title: "Gira Ambulante",
    description: "Nuestra gira de cine itinerante",
    color: "border-[#FF646D]",
    link: "/iniciativa/gira/",
    src: "gira_ambulante_small.png",
  },
  "ambulante-presenta": {
    accent: "text-presenta group-hover:text-neutral-900",
    "positive-text": "text-neutral-300",
    "negative-text": "hover:text-neutral-900 hover:bg-presenta",
    "link-positive": "dark",
    "link-negative": "light",
    hr: "border-presenta group-hover:border-neutral-900",
    title: "Ambulante Presenta",
    description:
      "Circuito de exhibición de cine documental desde el quehacer colaborativo. ",
    color: "border-[#FFDA47]",
    link: "/blog/anunciamos-el-regreso-de-ambulante-presenta",
    src: "ambulante_presenta_small.png",
  },
  "ambulante-mas-alla": {
    accent: "text-mas-alla group-hover:text-neutral-900",
    "positive-text": "text-neutral-300",
    "negative-text": "hover:text-neutral-900 hover:bg-mas-alla",
    "link-positive": "dark",
    "link-negative": "light",
    hr: "border-mas-alla group-hover:border-neutral-900",
    title: "Ambulante Más Allá",
    description: "Capacitación en producción documental",
    color: "border-[#B5A17B]",
    link: "/ambulante-mas-alla/",
    src: "ambulante_mas_alla_small.png",
  },
  ediciones: {
    accent: "text-ediciones group-hover:text-neutral-900",
    "positive-text": "text-neutral-300",
    "negative-text": "hover:text-neutral-900 hover:bg-ediciones",
    "link-positive": "dark",
    "link-negative": "light",
    hr: "border-ediciones group-hover:border-neutral-900",
    title: "Ambulante Ediciones",
    description: "Obras para expandir la cultura del cine",
    color: "border-[#63E084]",
    link: "/publicaciones/",
    src: "ambulante_mas_alla_small.png",
  },
  incidencias: {
    accent: "text-incidencias group-hover:text-neutral-900",
    "positive-text": "text-neutral-300",
    "negative-text": "hover:text-neutral-900 hover:bg-ediciones",
    "link-positive": "dark",
    "link-negative": "light",
    hr: "border-incidencias group-hover:border-neutral-900",
    title: "Incidencias",
    description: "Campañas sobre causas que merecen ser acompañadas.",
    link: "/incidencias",
  },
  distribucion: {
    accent: "text-distribucion group-hover:text-neutral-900",
    "positive-text": "text-neutral-300",
    "negative-text": "hover:text-neutral-900 hover:bg-distribucion",
    "link-positive": "dark",
    "link-negative": "light",
    hr: "border-distribucion group-hover:border-neutral-900",
    title: "Distribución",
    description: "Obras fundamentales para expandir la cultura del cine.",
    link: "/distribucion",
  },
  becasyfondos: {
    accent: "text-becasyfondos group-hover:text-neutral-900",
    "positive-text": "text-neutral-300",
    "negative-text": "hover:text-neutral-900 hover:bg-becasyfondos",
    "link-positive": "dark",
    "link-negative": "light",
    hr: "border-becasyfondos group-hover:border-neutral-900",
    title: "Becas y Fondos",
    description: "Obras fundamentales para expandir la cultura del cine.",
    link: "/becas-y-fondos",
  },
  proyectosEspeciales: {
    accent: "text-pryectos-especiales group-hover:text-neutral-900",
    "positive-text": "text-neutral-300",
    "negative-text": "hover:text-neutral-900 hover:bg-proyectos-especiales",
    "link-positive": "dark",
    "link-negative": "light",
    hr: "border-proyectos-especiales group-hover:border-neutral-900",
    title: "Proyectos Especiales",
    description: "Obras fundamentales para expandir la cultura del cine.",
    link: "/proyectos-especiales",
  },
};

const parentVariant = {
  initial: { scaleY: 0 },
  animate: {
    scaleY: 1,
    transition: {
      staggerChildren: 0.1,

      delayChildren: 0.1,
    },
  },
  exit: {
    opacity: 0,
    scaleY: 0,
  },
};

const childVariant = {
  initial: {
    y: -100,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

const options = [
  "gira-ambulante",
  "ambulante-presenta",
  "ambulante-mas-alla",
  "ediciones",
];

export default function Hamburger({ theme, options }) {
  const [open, setOpen] = useState(false);
  return (
    <div className="lg:hidden">
      <MenuIcon
        color={theme === "dark" ? "white" : "dark"}
        onClick={() => setOpen((e) => !e)}
        width={22}
        height={22}
      />
      <AnimatePresence>
        {open ? (
          <>
            <Options
              onClose={() => setOpen(false)}
              // NOT IMPLEMENTED YET
              // list={options.filter(
              //   (o) => o.label !== "Iniciativas" && o.label !== "Reseñas"
              // )}
              list={options.filter(
                (o) => o.label !== "" && o.label !== "Programas"
              )}
            />

            <BlockingOverlay z={"50"} transparent onClick={setOpen} />
          </>
        ) : null}
      </AnimatePresence>
    </div>
  );
}

function Options({ list, onClose }) {
  const [selected, setSelected] = useState("Español");
  return (
    <motion.ul
      variants={parentVariant}
      initial="initial"
      animate="animate"
      exit="exit"
      className="absolute right-0 top-0 z-[100] origin-top divide-y bg-neutral-50 shadow-2xl w-full h-screen overflow-scroll pb-10 "
    >
      <div className="flex items-center bg-neutral-50 py-3  ">
        <Link href="/" passHref className="flex items-center">
          <GlobalLogo
            className={classNames(
              "mx-4 sm:w-[188px] md:mx-8 lg:w-[204px] ",
              "text-neutral-900"
            )}
          />
        </Link>
        <div className="grow " />
        <svg
          onClick={onClose}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="flex-end h-6 w-6 sm:mx-4 "
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>

      {list.map((option, i) => {
        return (
          <motion.li
            className="cursor-pointer bg-white sm:px-4 sm:py-2 md:px-8"
            variants={childVariant}
            key={i}
          >
            <Link href={option.href} legacyBehavior>
              <p className="font-semibold">{option.label}</p>
            </Link>
          </motion.li>
        );
      })}
      <div className="bg-neutral-50 sm:px-4 sm:py-2 md:px-8">
        <Text.Overline size="md" className="text-neutral-500">
          &nbsp; Programas Ambulante
        </Text.Overline>
      </div>
      <div className="bg-white">
        <InitiativesPopover />
      </div>
      <div className="h-[16px] bg-neutral-50 sm:px-4 md:px-8"></div>
      {/* Not implemented yet */}
      {/* <div className="bg-neutral-50">
        <p className="p-4 text-sm font-semibold text-neutral-400">
          &nbsp;
          INICIATIVAS AMBULANTE&nbsp;
        </p>
      </div>
      {options.map((option) => {
        return (
          <motion.li key={option} variants={childVariant}>
            <SubItems option={initiatives[option]} key={option} />
          </motion.li>
        );
      })} */}
      {/* <div className="bg-neutral-50">
        <p className="p-4 text-sm font-semibold text-neutral-400">
          &nbsp;
          IDIOMA DEL SITIO&nbsp;
        </p>
      </div>
      {["Español", "English"].map((option) => {
        return (
          <motion.li
            key={option}
            variants={childVariant}
            onClick={() => setSelected(option)}
            className="cursor-pointer sm:py-2 sm:px-4 md:px-8"
          >
            <div className="flex items-center sm:px-4 md:px-0">
              {selected === option ? (
                <CheckCircleIcon width={24} height={24} />
              ) : (
                <div className="w-[24px]" />
              )}
              <p className="py-2 px-4 font-semibold">{option}</p>
            </div>
          </motion.li>
        );
      })} */}
    </motion.ul>
  );
}

function SubItems({ option }) {
  return (
    <Link href={option.link} legacyBehavior>
      <div
        className={twMerge(
          "z-10 flex cursor-pointer gap-4 border-l-[6px] sm:px-3 md:px-7",
          option.color
        )}
      >
        <div className="py-2">
          <p className="text-sm font-bold">{option.title}</p>
          <p className="text-sm text-neutral-400">{option.description}</p>
        </div>
      </div>
    </Link>
  );
}
