import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import Image from "next/legacy/image";
import Link from "next/link";
import React from "react";
import { twMerge } from "tailwind-merge";
import BlockingOverlay from "../lib/BlockingOverlay";

const initiatives = {
  "gira-ambulante": {
    accent: "text-gira group-hover:text-neutral-900",
    "positive-text": "text-neutral-300",
    "negative-text": "hover:text-neutral-900 hover:bg-gira",
    "link-positive": "dark",
    "link-negative": "light",
    hr: "border-gira group-hover:border-neutral-900",
    title: "Gira Ambulante",
    description: "Nuestra gira de cine itinerante",
    color: "border-[#FF646D]",
    link: "/iniciativa/gira/",
    src: "thumb-gira.png",
  },
  "ambulante-presenta": {
    accent: "text-presenta group-hover:text-neutral-900",
    "positive-text": "text-neutral-300",
    "negative-text": "hover:text-neutral-900 hover:bg-presenta",
    "link-positive": "dark",
    "link-negative": "light",
    hr: "border-presenta group-hover:border-neutral-900",
    title: "Ambulante Presenta",
    description:
      "Circuito de exhibición de cine documental desde el quehacer colaborativo. ",
    color: "border-[#FFDA47]",
    link: "/blog/anunciamos-el-regreso-de-ambulante-presenta",
    src: "thumb-presenta.png",
  },
  "ambulante-mas-alla": {
    accent: "text-mas-alla group-hover:text-neutral-900",
    "positive-text": "text-neutral-300",
    "negative-text": "hover:text-neutral-900 hover:bg-mas-alla",
    "link-positive": "dark",
    "link-negative": "light",
    hr: "border-mas-alla group-hover:border-neutral-900",
    title: "Ambulante Más Allá",
    description: "Capacitación en producción documental",
    color: "border-[#B5A17B]",
    link: "/ambulante-mas-alla/",
    src: "thumb-alla.png",
  },
  ediciones: {
    accent: "text-ediciones group-hover:text-neutral-900",
    "positive-text": "text-neutral-300",
    "negative-text": "hover:text-neutral-900 hover:bg-ediciones",
    "link-positive": "dark",
    "link-negative": "light",
    hr: "border-ediciones group-hover:border-neutral-900",
    title: "Ambulante Ediciones",
    description: "Obras para expandir la cultura del cine",
    color: "border-[#63E084]",
    link: "/publicaciones/",
    src: "thumb-ediciones.png",
  },
  incidencias: {
    accent: "text-incidencias group-hover:text-neutral-900",
    "positive-text": "text-neutral-300",
    "negative-text": "hover:text-neutral-900 hover:bg-ediciones",
    "link-positive": "dark",
    "link-negative": "light",
    hr: "border-incidencias group-hover:border-neutral-900",
    title: "Incidencias",
    description: "Campañas sobre causas que merecen ser acompañadas.",
    link: "/incidencias",
  },
  distribucion: {
    accent: "text-distribucion group-hover:text-neutral-900",
    "positive-text": "text-neutral-300",
    "negative-text": "hover:text-neutral-900 hover:bg-distribucion",
    "link-positive": "dark",
    "link-negative": "light",
    hr: "border-distribucion group-hover:border-neutral-900",
    title: "Distribución",
    description: "Obras fundamentales para expandir la cultura del cine.",
    link: "/distribucion",
  },
  becasyfondos: {
    accent: "text-becasyfondos group-hover:text-neutral-900",
    "positive-text": "text-neutral-300",
    "negative-text": "hover:text-neutral-900 hover:bg-becasyfondos",
    "link-positive": "dark",
    "link-negative": "light",
    hr: "border-becasyfondos group-hover:border-neutral-900",
    title: "Becas y Fondos",
    description: "Obras fundamentales para expandir la cultura del cine.",
    link: "/becas-y-fondos",
  },
  proyectosEspeciales: {
    accent: "text-pryectos-especiales group-hover:text-neutral-900",
    "positive-text": "text-neutral-300",
    "negative-text": "hover:text-neutral-900 hover:bg-proyectos-especiales",
    "link-positive": "dark",
    "link-negative": "light",
    hr: "border-proyectos-especiales group-hover:border-neutral-900",
    title: "Proyectos Especiales",
    description: "Obras fundamentales para expandir la cultura del cine.",
    link: "/proyectos-especiales",
  },
};

const parentVariant = {
  initial: { scale: 0 },
  animate: {
    scale: 1,
    transition: {
      staggerChildren: 0.1,

      delayChildren: 0.1,
    },
  },
  exit: {
    opacity: 0,
    scaleY: 0,
  },
};

const childVariant = {
  initial: {
    x: -200,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

const options = [
  "gira-ambulante",
  "ambulante-presenta",
  "ambulante-mas-alla",
  "ediciones",
];

function SubItems({ option }) {
  return (
    <Link href={option.link} legacyBehavior>
      <div
        className={twMerge(
          "z-10 flex cursor-pointer gap-4 border-l-[6px] px-4",
          option.color
        )}
      >
        <Image
          src={`/next_assets/img/brand-assets/${option.src}`}
          layout="fixed"
          width={48}
          height={48}
          alt="thumbnail"
          objectFit="cover"
          objectPosition={{ top: 0 }}
        />
        <div>
          <p className="font-bold">{option.title}</p>
          <p className="text-sm text-neutral-400">{option.description}</p>
        </div>
      </div>
    </Link>
  );
}

export default function PopSubMenu({ open, setOpen }) {
  return (
    <AnimatePresence>
      {open ? (
        <>
          <motion.div
            variants={parentVariant}
            initial="initial"
            animate="animate"
            exit="exit"
            className="absolute top-[78px] z-50 grid w-[800px] grid-cols-2 gap-4 bg-white p-8 shadow-xl"
          >
            <div className="absolute -top-1 right-1/2 h-3 w-3 rotate-45 bg-white"></div>
            {/* <p className="col-span-2 text-sm font-semibold tracking-widest text-neutral-900">
              ACTIVAS
            </p> */}
            {options.map((option) => {
              return (
                <motion.div key={option} variants={childVariant}>
                  <SubItems option={initiatives[option]} />
                </motion.div>
              );
            })}
          </motion.div>
          <BlockingOverlay
            onEnter={setOpen}
            z={"z-20"}
            transparent
            topOffset={"top-[78px]"}
          />
        </>
      ) : null}
    </AnimatePresence>
  );
}
